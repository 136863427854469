<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    title="其他信息"
    width="700"
    :visible="visible"
    :closable="false"
    :footer="null"
    @close="onClose"
  >
    <div v-if="detail.id">
      <a-row>
        <a-col :span="4">平台：</a-col>
        <a-col>{{ (Platforms.find(item => item.value === detail.platform_id) || {}).text || '-' }}</a-col>
      </a-row>
      <a-row>
        <a-col :span="4">支付方式：</a-col>
        <a-col>{{ (PayChannels.find(item => item.value === detail.pay_channel) || {}).text || '-' }}</a-col>
      </a-row>
      <a-row>
        <a-col :span="4">渠道流水ID：</a-col>
        <a-col>{{ detail.trade_no }}</a-col>
      </a-row>
      <a-row>
        <a-col :span="4">充值次数：</a-col>
        <a-col>{{ times }}</a-col>
      </a-row>
    </div>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose } from 'vue'
import { Platforms, PayChannels } from '@/utils/constantList'
import { payUserInfo } from '@/api/reseller'

// 抽屉展示状态
let visible = ref(false)
// 数据
let detail = ref({})
let times = ref(0)

// 打开抽屉
function showDrawer (record) {
  // 展开
  visible.value = true
  // 数据
  detail.value = record
  // 获取详情
  getDetail()
}

// 关闭抽屉
function onClose () {
  times.value = 0
  detail.value = {}
  visible.value = false
}

// 获取详情
function getDetail () {
  payUserInfo({ uid: detail.value.user_id, source: detail.value.source }).then((res) => {
    const { code, data } = res
    if (code === 0) {
      times.value = data.times
    }
  }).catch(err => {
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.ant-row {
  height: 40px;
  .ant-col {
    text-align: right;
    &:first-child {
      margin-right: 6px;
    }
  }
}
</style>